import React from "react";
import "./Team.css";
import AtorImg from "../assets/rui4.JPG"; // Make sure the path is correct
import AtrizImg from "../assets/atriz.jpg"; // Make sure the path is correct

function Team() {
  return (
    <section className="team">
      <div className="container">
        <h1>A DIREÇÃO</h1>
        <div className="row">
          <div className="col-md-4 offset-md-2 profile text-center spacing-right">
            <div className="img-box">
            <img src={AtorImg} alt="Rui Castro" className="rui-castro-image" />
              {}

              <ul>
                <a href="#">
                  <li>
                    <i href="https://pt.linkedin.com/in/rui-castro-248847102" className="fab fa-linkedin"></i>
                  </li>
                </a>
              </ul>
            </div>

            <h2>Rui Castro</h2>
            <h3> Sócio </h3>
            <p>
            Rui Castro é licenciado em Engenharia Eletrotécnica pelo ISEP e Mestre em Tecnologia e Inovação pela Universidade de Aveiro.

Especialista em Sistemas de Potência da Ordem dos Engenheiros Técnicos, é também docente do ISEP, na área de Sistemas de Elétricos de Energia, e Especialista em Eletricidade e Energia do IPP.
            </p>
          </div>

          <div className="col-md-4 profile text-center">
            <div className="img-box">
            <img src={AtrizImg} className="img-responsive carla-silva-image" alt="Carla Silva" />
              {/* Added alt attribute for accessibility */}
              <ul> 
                <a href="#">
                  <li>
                    <i className="fab fa-linkedin"></i>
                  </li>
                </a>
              </ul>
            </div>

            <h2>Carla Silva</h2>
            <h3> Sócia Fundadora </h3>
            <p>
              Carla Silva é licenciada em contabilidade e Mestre em Gestão. Membro efetiva da Ordem dos Contabilistas, é também empresária, contabilista certificada
              e formadora profissional na área financeira e de gestão.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
