import React from 'react';
import "./HeroStyles.css";
import videoSource from '../assets/videoTorreEpico.mp4';

function Hero(props) {
  
  return (
    <div className={props.cName}>
      {props.isVideo ? (
        <>
          <video autoPlay loop muted playsInline className="hero-video">
            <source src={videoSource} type="video/mp4" />
            O seu navegador não suporta vídeos.
          </video>
          <div className="video-overlay"></div>
        </>
      ) : (
        <img alt="HeroImg" src={props.heroImg} className="hero-img" />
      )}

      {props.mobileImageSrc && <div className="hero-mobile-image" style={{ backgroundImage: `url(${props.mobileImageSrc})` }}></div>}

      <div className="hero-text">
        <h1 className={props.titleClass || ''}>{props.title}</h1>
        <p>{props.text}</p>
        <a href={props.url} className={props.btnClass}>
          {props.buttonText}
        </a>
      </div>
    </div>
  );
}

export default Hero;